export enum Navbar {
  Dashboard = 'admin/dashboard',
  Clinics = 'admin/clinics',
  Articles = 'admin/articles',
  Podcasts = 'admin/podcasts',
  ManageUsers = 'admin/manage-users',
  Pages = 'admin/pages',
  Quotes = 'admin/quotes',
  Programs = 'admin/programs'
}
