import { Component, Input, OnInit } from '@angular/core';
import { IResource } from '@core/interfaces/common/iresource';

@Component({
  selector: 'sjc-admin-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss'
})
export class CarouselComponent implements OnInit {
  @Input() resources: IResource[] = [];
  @Input() itemWindow: number = 3;
  @Input() cardClass: string = '';
  @Input() btnLabel: string = '';
  @Input() btnClass: string = '';

  displayGroup: IResource[] = [];
  start: number = 0;
  mid: number = 0;
  end: number = 0;
  current!: IResource;

  ngOnInit(): void {
    if (this.resources.length > this.itemWindow) {
      var offset = (this.itemWindow - 1) / 2;

      this.mid = Math.ceil(this.resources.length / 2);
      this.start = this.mid - offset;
      this.end = this.mid + offset;

      for (let i = this.start - 1; i < this.end; i++) {
        this.displayGroup.push(this.resources[i]);
      }
    } else {
      this.displayGroup = this.resources;
    }
  }

  moveLeft(): void {
    if (this.start > 1) {
      this.displayGroup.pop();
      this.displayGroup.unshift(this.resources[this.start - 2]);
      this.start -= 1;
      this.end -= 1;
    }
  }

  moveRight(): void {
    if (this.end < this.resources.length) {
      this.displayGroup.shift();
      this.displayGroup.push(this.resources[this.end]);
      this.end += 1;
      this.start += 1;
    }
  }
}

