<div class="px-0 w-full h-full flex flex-col">
  <div class="flex-grow">
    <ng-container>
      <div class="overflow-auto px-1">
        <table class="w-full min-w-max table-auto text-left border-separate border-spacing-y-4 overflow-scroll">
          <thead class="bg-dark-blue text-white">
            <tr>
            @if (isSelectable) {
              <td class="w-2"></td>
            }
            @for (col of columns; track col) {
              <th class="pr-4 py-4" [ngClass]="{ 'px-4' : !isSelectable }">
                <p class="text-sm font-medium bg-none flex items-center justify-between gap-2">
                  {{colHeaders[col]}}
                </p>
              </th>
            }
            @if(isDelete || isEdit) {
              <td class="pr-4">
                <p class="text-sm font-semibold bg-none text-dark-purple flex items-center justify-between gap-2"></p>
              </td>
            }
          </tr>
          </thead>
          <ng-container *ngIf="isDraggable; else noDrag">
            <tbody *ngIf="tableData.length > 0" cdkDropList (cdkDropListDropped)="drop($event)">
              @for (data of tableData; track $index) {
                <tr
                  cdkDrag
                  cdkDragLockAxis="y"
                  [cdkDragData]="data"
                  class="bg-egg-shell m-5 rounded-md border outline outline-1 outline-grey drag">
                  @for (col of columns; track col; let i = $index) {
                    @if (isSelectable && i == 0) {
                      <td>
                        <sjc-admin-input
                          [ariaLabel]="'Choose item to bulk delete' + data.id"
                          [inputType]="inputType.Checkbox"
                          [checked]="data.checked ?? false"
                          placeHolder="data.id"
                          (checkboxClick)="rowSelected(data.id)">
                        </sjc-admin-input>
                      </td>
                    }
                    <td
                      cdkDragHandle
                      class="p-2 px-4 text-sm text-dark-grey drag-width"
                      [ngClass]="{ 'cursor-grab' : isDraggable }">
                      <div class="flex flex-row items-center">
                        @if (isDraggable && i == 0) {
                            <i class="uil uil-draggabledots mr-2"></i>
                          }
                          @if (isLinkableTitle && (col == titleCol.Name || col == titleCol.Title)) {
                            <sjc-admin-btn
                              btnType="button"
                              class="flex flex-grow drag-text w-auto whitespace-normal break-words"
                              (click)="goToUrl(data.website)"
                              [btnClass]="Button.BlueUnderlineTextLeft"
                              [applyTextCenter]="false">
                              {{ data[col] }}
                            </sjc-admin-btn>
                        } @else {
                          <p class="drag-text">{{ data[col] }}</p>
                        }
                      </div>
                    </td>
                  }
                  <td class="mr-2 text-right pr-3 object-right icons">
                    @for (item of actions; track $index) {
                      @if (item.isActive) {
                        <span class="p-2 text-2xl text-dark-grey" (click)="item.handler(data, item.type)">
                          <i [class]="item.icon"></i>
                        </span>
                      }
                    }
                  </td>
                </tr>
              }
            </tbody>
          </ng-container>
          <ng-template #noDrag>
            <tbody *ngIf="tableData && tableData.length > 0">
              @for (data of tableData; track $index) {
                <tr class="bg-egg-shell m-5 rounded-md border outline outline-1 outline-grey">
                    @for (col of columns; track col; let i = $index) {
                      @if (isSelectable && i == 0) {
                        <td>
                          <sjc-admin-input
                          [ariaLabel]="'Choose item to bulk delete' + data.id"
                          [checked]="data.checked ?? false"
                          [inputType]="inputType.Checkbox"
                          (checkboxClick)="rowSelected(data.id)">
                        </sjc-admin-input>
                        </td>
                      }
                      <td class="p-2 px-4 text-sm text-dark-grey">
                        @if (isLinkableTitle && (col == titleCol.Name || col == titleCol.Title)) {
                          <sjc-admin-btn
                            btnType="button"
                            class="w-auto whitespace-normal break-words"
                            (click)="goToUrl(data.website)"
                            [btnClass]="Button.BlueUnderlineTextLeft"
                            [applyTextCenter]="false">
                            {{ data[col] }}
                          </sjc-admin-btn>
                        } @else {
                          {{ data[col] }}
                        }
                      </td>
                  }
                  <td class="mr-2 rounded-r-2xl text-right pr-3 flex justify-end">
                  @for (item of actions; track $index) {
                    @if (item.isActive) {
                      <div class=" p-2 text-2xl text-dark-grey" (click)="item.handler(data, item.type)">
                        <i [class]="item.icon"></i>
                      </div>
                    }
                  }
                  </td>
                </tr>
              }
            </tbody>
          </ng-template>
        </table>
      </div>
    </ng-container>
  </div>
</div>
