import { Component, Input, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sjc-admin-hero',
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent {
  private titleService: Title = inject(Title);
  title: string = '';
  
  ngOnInit(): void {
    this.title = this.titleService.getTitle();
  }
}
