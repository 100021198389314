import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Action } from '@core/enums/iaction';
import { IAction } from '@core/interfaces/iaction';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TitleCol } from '@core/enums/title-col';
import { InputType } from '@core/enums/input-type';
import { Button } from '@core/enums/button';

@Component({
  selector: 'sjc-admin-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent {
  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() drag = new EventEmitter();
  @Output() select = new EventEmitter();
  @Input() columns: string[] = [];
  @Input() colHeaders: any;
  @Input() tableData: any[] = [];
  @Input() pageSize: number = 10;
  @Input() pageIndex: number = 1;
  @Input() totalData: number = 0;
  @Input() isLinkableTitle: boolean = false;
  @Input() isDraggable: boolean = false;
  @Input() isDelete: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() isSelectable: boolean = false;

  Button = Button;
  actions: IAction[] = [];
  actionType = Action;
  titleCol = TitleCol;
  inputType = InputType;

  ngOnInit(): void {
    this.actions = [
      {
        label: 'Edit',
        isActive: this.isEdit,
        icon: 'uil uil-edit',
        handler: this.action.bind(this),
        type: this.actionType.edit
      },
      {
        label: 'Delete',
        isActive: this.isEdit,
        icon: 'uil uil-multiply',
        handler: this.action.bind(this),
        type: this.actionType.delete
      },
    ];
  }

  action(item: any, actionType: string) {
    switch (actionType) {
      case Action.edit:
        this.edit.emit(item);
        break;
      case Action.delete:
        this.delete.emit(item);
        break;
      case Action.add:
        this.add.emit(item);
        break;
      default:
        break;
    }
  }

  goToUrl(url: string): void {
    if (!url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }

    window.open(url, "_blank");
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.tableData, event.previousIndex, event.currentIndex);
    this.drag.emit(this.tableData);
  }

  rowSelected(event: any): void {
    this.select.emit(event);
  }
}
