import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Button } from '@core/enums/button';
import { AuthService } from '@core/services/auth.service';
import { forkJoin, take } from 'rxjs';


@Component({
  selector: 'sjc-admin-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss'
})
export class UserMenuComponent {
  private auth = inject(AuthService);
  private router = inject(Router);

  Button = Button;
  userInfo = this.auth.getCurrentUser();

  ngOnInit(): void {
    this.auth.userUpdated$.pipe(take(1)).subscribe((success: boolean) => {
      if (success) this.userInfo = this.auth.getCurrentUser();
    })
  }

  logout(): void {
    this.auth.logout();
  }

  editProfile(): void {
    this.router.navigateByUrl('admin/account-settings');
  }
}
