import { Component, OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ToastService } from '@core/services/toast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sjc-admin-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent implements OnInit {
  subscription: Subscription | any;
  message?: string;
  icon?: string;
  headerTxt?: string;

  constructor(private toastService: ToastService, public snackBarRef: MatSnackBarRef<ToastComponent>) { }

  ngOnInit() {
    this.subscription = this.toastService.$responseMessage.subscribe({
      next: (data: any) => {
        if (data) {
          this.message = data.message.message;
          this.icon = data.icon;
          this.headerTxt = data.message.header;
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
