export function getSearchUrl(baseUrl: string, params: any): string {
    return baseUrl.concat(
        Object.entries(params).map(([key, val]) => {
          if (val != null) {
            return `${key}=${val}`
          }
          return
        }).filter(Boolean).join('&')
      );
}

export function paginate(results: any[], pageSize: number): any[] {
  return results.reduce(
    (arr: any, items: any, i: number) =>
      (i % pageSize
        ? arr[arr.length - 1].push(items)
        : arr.push([items])) && arr,
    []
  );
}

export function convertDate(utcTimestamp: string): Date {
  const currentDate = new Date();
  const userUtcOffsetMinutes = currentDate.getTimezoneOffset();
  const date = new Date(utcTimestamp);
  const localTime = new Date(date.getTime() - (userUtcOffsetMinutes * 60 * 1000));

  return localTime;
}

export function sortData(data: any[], active: string, direction: string, originalArray: any[]): any[] {
  if (direction === '') return originalArray;

  if (active === 'consultant') {
      return data.sort((a, b) => {
        const lastNameComparison = (a['consultantLastName'] ?? '').localeCompare(b['consultantLastName'] ?? '');
        const firstNameComparison = (a['consultantFirstName'] ?? '').localeCompare(b['consultantFirstName'] ?? '');

        if (direction === 'asc') {
          if (lastNameComparison !== 0)
            return lastNameComparison;
          else
            return firstNameComparison;
        } else if (direction === 'desc') {
          if (lastNameComparison !== 0)
            return -lastNameComparison;
          else
            return -firstNameComparison;
        }
      });
  } else {
    return data.sort((a, b) => {
      if (direction === 'asc')
         return (a[active] ?? '').localeCompare(b[active] ?? '');
      else if ((direction === 'desc'))
         return (b[active] ?? '').localeCompare(a[active] ?? '');
    });
  }
}
