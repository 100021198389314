import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IToast } from '@core/interfaces/itoast';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  responseMessage: string = '';
  $responseMessage = new BehaviorSubject<any>(this.responseMessage);
  snackConfig: MatSnackBarConfig<any> = {
      duration: 4000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
  }

  constructor(private _snackBar: MatSnackBar) { }

  success(message: IToast) {
    this.snackConfig.panelClass = 'success-snackbar';
    this.$responseMessage.next({ message: message, icon: 'uil uil-check-circle' });
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }

  error(message: IToast) {
    this.snackConfig.panelClass = 'error-snackbar';
    this.$responseMessage.next({ message: message, icon: 'uil uil-exclamation-circle' });
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }

  info(message: IToast) {
    this.snackConfig.panelClass = 'info-snackbar';
    if (message.duration) this.snackConfig.duration = message.duration;
    this.$responseMessage.next({ message: message, icon: message.icon ?? 'uil uil-bookmark' });
    this._snackBar.openFromComponent(ToastComponent, this.snackConfig);
  }
}
