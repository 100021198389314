import { Injectable, inject } from "@angular/core";
import { IUser, IUserSearchRequest, IUserSearchResponse, IUserSearchResponseCollection } from "@core/interfaces/iuser";
import { DataService } from "@core/services/data.service";
import { Observable } from "rxjs";
import { getSearchUrl } from "./helpers.service";
import { IToast } from "@core/interfaces/itoast";

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private data = inject(DataService);

  search(req: IUserSearchRequest): Observable<IUserSearchResponseCollection> {
    const url = getSearchUrl('user/search?', req);
    return this.data.get(url);
  }

  getAll(): Observable<IUserSearchResponse[]> {
    return this.data.get('user/all');
  }

  add(req: IUser): Observable<boolean> {
    return this.data.post('user', req, { message: 'User has been successfully added.' })
  }

  update(req: IUser): Observable<boolean> {
    return this.data.put('user', req, { message: 'User has been successfully updated.' })
  }

  delete(req: IUser): Observable<boolean> {
    return this.data.delete('user', req, { message: 'User has been successfully deleted.' })
  }

  isDuplicateEmail(email: string, message?: IToast): Observable<boolean> {
    return this.data.get(`user/duplicate-email?email=${email}`, message);
  }

  getById(id: number, message?: IToast): Observable<IUser> {
    return this.data.get(`user/${id}`, message);
  }
}
