import { Component, Input } from '@angular/core';
import { Button } from '@core/enums/button';

@Component({
  selector: 'sjc-admin-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() cardClass: string = 'card-white';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() secondaryDescription: string = '';
  @Input() hasButton: boolean = false;
  @Input() btnClass: string = Button.DarkBlue;
  @Input() imageLink: string = '';
  @Input() btnLabel: string = 'EXPLORE';
}
