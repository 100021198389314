<div>
  <div
    [matMenuTriggerFor]="menu"
    ariaLabel="Open User Info"
    icon="uil-user-circle">
    <span class="sr-only">Profile Button</span>
    <i Class="uil uil-user-circle user-profile text-[36px] text-dark-blue cursor-pointer"></i>
  </div>
  <mat-menu
    #menu="matMenu"
    (close)="menu"
    xPosition="before">
    <div class="user-info-container p-3">
      <div class="user-info">
        <p role="menuitem" class="cursor-pointer hover:text-dark-blue" (click)="logout()">
          Log out
        </p>
        <p role="menuitem" class="cursor-pointer hover:text-dark-blue" (click)="editProfile()">
          Account Settings
        </p>
      </div>
    </div>
  </mat-menu>
</div>
