<div class="w-screen lg:h-full">
  <div class="container sm:mx-auto mx-3 py-2">
    <div class="grid grid-cols-3 gap-4 items-center">
      <div class="col-span-1 flex items-center">
        <a href="/">
          <img src="assets/images/YouthWellness-Logo-Ideas.png" alt="SJC Youth Logo" width="200"/>
        </a>
      </div>
    </div>
  </div>
</div>
