<div class="grid grid-cols-{{ itemWindow }} mt-4 justify-center sm:flex gap-x-16 hidden">
  <sjc-admin-card
    *ngFor="let r of displayGroup"
    [title]="r.title!"
    [description]="r.description"
    [imageLink]="r.imageLink"
    [btnClass]="btnClass"
    [btnLabel]="btnLabel"
    [cardClass]="cardClass">
  </sjc-admin-card>
</div>
<div class="sm:flex justify-center gap-x-2 mt-2 hidden">
  <div (click)="moveLeft()" class="cursor-pointer font-bold text-dark-salmon text-[24px]"><-</div>
  <div (click)="moveRight()" class="cursor-pointer font-bold text-dark-salmon text-[24px]">-></div>
</div>

<div class="flex flex-col mt-4 items-center gap-y-4 block sm:hidden">
  <sjc-admin-card
    *ngFor="let r of displayGroup"
    [title]="r.title!"
    [description]="r.description"
    [imageLink]="r.imageLink"
    [btnClass]="btnClass"
    [btnLabel]="btnLabel"
    [cardClass]="cardClass">
  </sjc-admin-card>
</div>
 