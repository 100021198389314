<div class="p-5">
  <div class="flex justify-between">
    <h1 class="text-[25px] font-bold text-purple">{{ config.title }}</h1>
    <i [mat-dialog-close]="false" class="text-midGrey my-auto cursor-pointer uil uil-multiply"></i>
  </div>
  <div class="py-5 w-full">
    <p [innerHTML]="config.message"></p>
  </div>
  <div class="flex justify-end gap-x-4">
    <sjc-admin-btn
      *ngIf="config?.cancelButton"
      [btnClass]="Button.White"
      [mat-dialog-close]="false">
      Cancel
    </sjc-admin-btn>
    <sjc-admin-btn
      [btnClass]="config.primaryBtnClass"
      (click)="submit(true)"
      cdkFocusInitial>
      {{ config.primaryBtnText }}
    </sjc-admin-btn>
  </div>
</div>
