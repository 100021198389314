import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { DataService } from "@core/services/data.service";
import { Observable, Subject, catchError, of, tap } from "rxjs";
import { getSearchUrl } from "./helpers.service";
import { IToast } from "@core/interfaces/itoast";
import { ILoginRequest, ILoginResponse } from "@core/interfaces/Ilogin";
import { environment } from "@env/environment";
import { Router } from "@angular/router";
import { ToastService } from "./toast.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { IChangePasswordRequest } from "@core/interfaces/change-password";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userUpdated$ = new Subject<boolean>();

  private readonly authToken = environment.authToken;
  private readonly userInfo = 'sjc-admin-user';

  private dataService = inject(DataService);
  private router = inject(Router);
  private toast = inject(ToastService);
  private jwtHelper = new JwtHelperService();
  currentUserInfo: WritableSignal<ILoginResponse | null>  = signal(null);

  captureUserInfo(loginRes: ILoginResponse): void {
    localStorage.setItem(this.authToken, JSON.stringify(loginRes.token));
    localStorage.setItem(this.userInfo, JSON.stringify(loginRes));
    const storedUserInfo = localStorage.getItem(this.userInfo);
    const userInfo = storedUserInfo ? JSON.parse(storedUserInfo) : null;
    this.currentUserInfo.set(userInfo);
  }

  login(payload: ILoginRequest, message?: IToast): Observable<ILoginResponse | null> {
    return this.dataService.post('auth/login', payload, message).pipe(
      tap((response: ILoginResponse) => {
        if (response) {
          this.captureUserInfo(response);
          this.router.navigateByUrl('/admin');
        }
      }),
      catchError((error: Error) => {
        this.toast.error({ message: error.message, header: "Error" });
        return of(null);
      })
    );
  }

  getCurrentUser(): any {
    return JSON.parse(window.localStorage.getItem(this.userInfo) ?? '{}');
  }

  forgotPassword(emailAddress: string, message?: IToast): Observable<boolean> {
    return this.dataService.post(`auth/forgot-password?emailAddress=${emailAddress}`, null, message);
  }

  isValidIdentifier(guid: string, message?: IToast): Observable<boolean> {
    return this.dataService.get(`auth/is-valid-identifier/${guid}`, message);
  }

  changePassword(payload: any, message?: IToast): Observable<boolean> {
    return this.dataService.put(`auth/password?password=${payload.password}&guid=${payload.guid}`, payload, message);
  }

  createPassword(params: any, message?: IToast): Observable<boolean> {
    const url = getSearchUrl('auth/create-password?', params);
    return this.dataService.put(url, message);
  }

  isTokenExpired(): boolean {
    const data = localStorage.getItem(this.authToken) || '';
    const token = data ? JSON.parse(data).token : null;
    if (token) {
      return this.jwtHelper.isTokenExpired(token) as boolean;
    }
    return true;
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  siteVerify(token: string): Observable<boolean> {
    return this.dataService.post('auth/captcha', { token: token });
  }

  checkLogin(): boolean {
    let result = false;
    const token = localStorage.getItem(this.authToken);
    if (token) {
      result = !this.jwtHelper.isTokenExpired(token);
    }
    return result;
  }

  verifyPassword(req: IChangePasswordRequest): Observable<boolean> {
    return this.dataService.post('auth/verify-password', req);
  }

  authorizedChangePassword(req: IChangePasswordRequest): Observable<boolean> {
    return this.dataService.put('auth/change-password', req, {
      message: 'Password has been successfully updated.'
    });
  }
}
