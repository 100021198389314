import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  $counter = new BehaviorSubject<number>(0);
  num: number = 0;

  public show() {
    this.num++
    this.$counter.next(this.num)
  }

  public hide() {
    this.num--
    this.$counter.next(this.num)
  }

  getSpinnerCounter():Observable<number>{
    return this.$counter.asObservable()
  }
}
