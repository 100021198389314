<div *ngIf="!isMobile" class="bg-white shadow-md flex flex-col md:h-full lg:block hidden">
  <div class="col-span-1 flex items-center px-8">
    <img 
      (click)="goHome()" 
      src="assets/images/YouthWellness-Logo-Ideas.png" 
      class="cursor-pointer" alt="SJC Youth Logo" width="200"/>
  </div>
  <ul class="text-base pl-4 py-4">
    <li *ngFor="let menuItem of navbarItems, let i = index"
      [ngClass]="{ 'bg-light-grey' : currentUrl == menuItem.link }"
      class="text-dark-blue font-semibold pr-8 pl-4 py-3">
      <a [href]="menuItem.link" class="hover:text-gray-400">
        <i *ngIf="menuItem.icon" [class]="menuItem.icon" class="text-[22px]"></i>&nbsp;&nbsp;
        {{ menuItem.label }}
      </a>
    </li>
  </ul>
</div>

<div class="lg:hidden flex pt-1">
  <div>
    <button
      aria-label="Open Side Navbar"
      mat-icon-button
      (click)="mobileNav.toggle()">
      <mat-icon>
        <i class="ml-2 text-4xl uil uil-bars cursor-pointer no-print"></i>
      </mat-icon>
    </button>
    <mat-sidenav-container>
      <mat-sidenav #mobileNav
        fixedInViewport="true"
        position="start"
        class="mobile-nav-position z-100">
        <mat-nav-list>
          <div class="col-span-1 flex justify-between gap-x-6 items-center px-4">
            <img src="assets/images/YouthWellness-Logo-Ideas.png"
              (click)="goHome(); mobileNav.close()"
              class="cursor-pointer"
              alt="SJC Youth Logo"
              width="200"/>
            <div class="flex justify-end pb-4">
              <i class="uil uil-multiply font-bold text-[24px] cursor-pointer"
                (click)="mobileNav.close()">
              </i>
            </div>
          </div>
          <ul class="text-base pl-4 py-4">
            <li *ngFor="let menuItem of navbarItems, let i = index"
              (click)="mobileNav.close()"
              [ngClass]="{ 'bg-light-grey' : currentUrl == menuItem.link }"
              class="text-dark-blue font-semibold pr-8 pl-4 py-3">
              <a [href]="menuItem.link" class="hover:text-gray-400">
                <i *ngIf="menuItem.icon" [class]="menuItem.icon" class="text-[22px]"></i>&nbsp;&nbsp;
                {{ menuItem.label }}
              </a>
            </li>
          </ul>
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</div>
