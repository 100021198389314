<div class="flex flex-col text-base">
  @if (label && label.length > 0 && inputType != InputType.Checkbox) {
    <label [ngClass]="{ 'required' : required, 'hidden' : inputType == InputType.Checkbox }"
      [class.pl-1]="true"
      id="label"
      [class]="labelColor"
      class="text-dark-grey"
      [for]="labelId">
      {{ label }}
    </label>
  }
  @switch (inputType) {
    @case (InputType.Text) {
      <div class="col-span-2 relative">
        <div class="flex flex-row items-center">
          <input type="text"
            [id]="labelId"
            [placeholder]="placeHolder"
            [attr.aria-label]="ariaLabel.length ? ariaLabel : label"
            [maxlength]="maxLength"
            [readonly]="readOnly"
            [formControl]="control"
            (keydown.enter)="onEnterKey()"
            [ngClass]="{ 'py-3 w-[606px]': hasSearch, 'border-red': control.invalid && (control.dirty || control.touched) }"
            class="w-full px-4 py-2 border border-dark-grey rounded bg-white text-black">
          @if (hasSearch) {
            <i class="uil uil-search absolute text-dark-blue right-0 mr-3 text-xl cursor-pointer"
              (click)="searchInput(control.value)"
              aria-label="Search">
            </i>
          }
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{
            customErrorMsg.length > 0
              ? customErrorMsg
              : (label + ' is ' + ((control.errors?.pattern || control.errors?.maxlength)
                ? 'invalid.'
                : 'required.'))
          }}
        </p>
      }
    }
    @case (InputType.Number) {
      <div class="col-span-2 relative">
        <div class="flex flex-row items-center">
          <input type="number"
            [id]="labelId"
            [placeholder]="placeHolder"
            [attr.aria-label]="ariaLabel.length ? ariaLabel : label"
            [formControl]="control"
            [readonly]="readOnly"
            min="0"
            class="w-full px-4 py-3 border border-dark-blue rounded bg-white"
            [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
          @if (hasSearch) {
            <i class="uil uil-search absolute text-dark-blue right-0 mr-3 text-xl cursor-pointer" (click)="searchInput(control.value)"></i>
          }
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{ customErrorMsg.length > 0 ? customErrorMsg : label + ' is required.' }}
        </p>
      }
    }
    @case (InputType.Email) {
      <input
        [placeholder]="placeHolder"
        [id]="labelId"
        [attr.aria-label]="ariaLabel.length ? ariaLabel : label"
        type="email"
        email="true"
        [maxLength]="maxLength"
        [readonly]="readOnly"
        [formControl]="control"
        class="w-full px-4 py-3 border border-dark-blue rounded bg-white"
        [ngClass]="{ 'border-red': control?.invalid && (control?.dirty || control?.touched) }">
      @if (control?.invalid  && (control?.dirty || control?.touched)) {
        <p class="text-sm text-red">
          {{
            customErrorMsg.length > 0
              ? customErrorMsg
              : (label + ' is ' + ((control.errors?.pattern || control.errors?.maxlength)
                ? 'invalid.'
                : 'required.'))
          }}
        </p>
      }
    }
    @case (InputType.Password) {
      <input
        [placeholder]="placeHolder"
        [id]="labelId"
        [attr.aria-label]="ariaLabel.length ? ariaLabel : label"
        [pattern]="PasswordRegex"
        type="password"
        [formControl]="control"
        class="w-full px-4 py-3 border border-dark-blue rounded bg-white"
        [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }">
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">Password is required.</p>
      }
      @if (control.touched && control.invalid && !control.errors.required) {
        <p class="text-sm text-red">Password must meet minimum requirements.</p>
      }
    }
    @case (InputType.Textarea) {
      <textarea
        [placeholder]="placeHolder"
        [maxlength]="maxLength"
        [id]="labelId"
        [attr.aria-label]="ariaLabel.length ? ariaLabel : label"
        [formControl]="control"
        rows="6"
        class="w-full px-4 py-2 border border-dark-blue rounded bg-white"
        [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"></textarea>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{ customErrorMsg.length > 0 ? customErrorMsg : label + ' is required.' }}
        </p>
      }
    }
    @case (InputType.File) {
      <input class="hidden" [id]="labelId"/>
      <div class="border p-6 flex justify-center bg-white rounded">
        <div fileDrop (fileDropped)="file($event)" class="border border-dashed border-1 cursor-pointer">
          <input
          type="file"
          name="file"
          id="fileInput"
          (change)="file($event)"
          aria-labelledby="file-label"
          accept="image/*"
          hidden
          class="cursor-pointer"
          [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"/>
          <label for="fileInput" id="file-label">
            <div class="text-center font-medium p-4">
              @if(control.value) {
                <p>{{ control.value }}</p>
              } @else {
                <i class="uil uil-upload-alt text-[24px]"></i>
              }<br>
              Drag & drop files or
              <span class="text-mandarin">
                Browse
              </span>
            </div>
          </label>
        </div>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{ customErrorMsg.length > 0 ? customErrorMsg : label + ' is required.' }}
        </p>
      }
    }
    @case (InputType.Select) {
      <mat-select
        class="bg-light-yellow border border-grey rounded bg-white pl-4 pr-2 py-2"
        [attr.aria-label]="ariaLabel && ariaLabel.length ? ariaLabel : label"
        [title]="ariaLabel && ariaLabel.length ? ariaLabel : label"
        [placeholder]="selectPlaceholder"
        [formControl]="control"
        [id]="labelId"
        (selectionChange)="handleSelectChange($event)">
        <mat-option
          *ngFor="let item of selectList"
          [value]="item.id">
          {{ item.name ?? item.title }}
        </mat-option>
      </mat-select>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{ customErrorMsg.length > 0 ? customErrorMsg : label + ' is required.' }}
        </p>
      }
    }
    @case (InputType.Checkbox) {
      <mat-checkbox
        [id]="labelId"
        color="primary"
        [attr.aria-labelledby]="ariaLabel && ariaLabel.length ? ariaLabel : label"
        [title]="ariaLabel && ariaLabel.length ? ariaLabel : label"
        [checked]="checked"
        [value]="checkboxValue | toString"
        (change)="handleCheckboxClick(checkboxValue)">
        <span [ngClass]="{ 'hidden' : !label.length }">
          {{ label.length == 0 ? ariaLabel : label }}
        </span>
      </mat-checkbox>
    }
    @case (InputType.Date) {
      <div class="flex flex-col">
        <mat-form-field 
          [ngClass]="{ 'border-red': control.invalid && (control.dirty || control.touched) }"
          class="w-full date-form-field border border-black rounded bg-white">
          <span class="flex items-end p-0">
            <mat-datepicker-toggle
              [for]="picker"
              class="text-dark-blue py-0">
            </mat-datepicker-toggle>
            <input
              matInput
              placeholder="Date"
              [attr.aria-label]="label"
              [matDatepicker]="picker"
              [formControl]="control"
              [min]="minDate"
              [max]="maxDate"
              class="w-full border-none">
          </span>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      @if (control.invalid  && (control.dirty || control.touched)) {
        <p class="text-sm text-red">
          {{ customErrorMsg.length > 0 ? customErrorMsg : label + ' is required.' }}
        </p>
      }
    }
  }
</div>
