<footer class="text-center bottom-0 w-full">
  <div class="bg-dark-blue text-white sm:px-24 px-8 py-16">
    <div class="grid md:grid-cols-4 grid-cols-1 justify-between sm:gap-y-8 gap-y-6">
      <div class="text-left sm:px-2 md:col-span-2">
        <h1 class="font-bold text-[28px]">About SJC Youth Wellness</h1>
        <p>
          The SJC Youth Wellness is dedicated to helping youth in our
          county with mental health and wellbeing. We are here to share
          resources and be a trusted ally. Community organizations,
          schools, and the community are working together to ensure
          youth have a place to turn to. Together we are committed to
          helping youth in San Joaquin County stay healthy, build
          knowledge and connect with valuable community resources.
        </p>
      </div>
      <div class="text-left md:pl-10">
        <h1 class="font-bold text-[28px]">Important Links</h1>
        <a href="/system-requirements" class="cursor-pointer">System Requirements</a><br>
        <a>Terms Of Use Agreement</a>
      </div>
      <div class="text-left flex flex-col md:pl-8">
        <h1 class="font-bold text-[28px]">Follow Us On</h1>
        <div class="flex flex-row gap-x-3 pt-2">
          <a href="http://twitter.com/thesjcoe" target="_blank">
            <img width="30" src="assets/images/X-Icon.png" alt="x or twitter"/>
          </a>
          <a href="http://www.facebook.com/thesjcoe" target="_blank">
            <img width="30" src="assets/images/facebook-icon.png" alt="facebook or meta"/>
          </a>
          <a href="http://instagram.com/sjcofficeofeducation" target="_blank">
            <img width="30" src="assets/images/insta-con.png" alt="instagram"/>
          </a>
          <a href="https://www.youtube.com/channel/UCNFSNbuwv0ajYgxaaXnhMKA" target="_blank">
            <img width="30" src="assets/images/youtube-icon.png" alt="SJC Youth Wellness Youtube"/>
          </a>
        </div>
      </div>
      <div class="sm:block hidden"></div>
      <div class="sm:block hidden"></div>
      <div class="md:pt-10 md:pl-10">
        <img src="assets/images/SJCOE-Internal-Logo.png" alt="SJCOE Logo" width="200"/>
      </div>
      <div class="text-left md:pl-8">
        <h1 class="font-bold text-[28px]">Codestack Sites</h1>
        <ul class="flex flex-col gap-y-2">
          <li>
            <a href="https://www.seis.org/" target="_blank">
              <div class="flex flex-row">
                <img src="assets/images/SEIS.png" alt="Seis Logo" width="20"/>
                &nbsp;SEIS
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.edjoin.org/" target="_blank">
              <div class="flex flex-row">
                <img src="assets/images/Edjoin.png" alt="Edjoin Logo" width="20"/>
                &nbsp;EDJOIN
              </div>
            </a>
          </li>
          <li>
            <a href="https://beyondsst.org/" target="_blank">
              <div class="flex flex-row">
                <img src="assets/images/BeyondSST.png" alt="BeyondSST Logo" width="20"/>
                &nbsp;BeyondSST
              </div>
            </a>
          </li>
          <li>
            <a href="https://codestackacademy.org/home" target="_blank">
              <div class="flex flex-row">
                <img src="assets/images/Codestack.png" alt="Codestack Logo" width="20"/>
                &nbsp;Codestack Academy
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="bg-orange sm:flex sm:flex-row sm:justify-between justify-center py-4 sm:px-28 font-semibold">
    <div class="mb-4 sm:mb-0">Suicide Prevention Lifeline 988</div>
    <div>Copyright 2024 All Rights Reserved.</div>
  </div>
</footer>
