import { Component, Input } from '@angular/core';
import { SUBMIT_LABEL } from '@core/constants/global-constants';
import { Button } from '@core/enums/button';

@Component({
  selector: 'sjc-admin-btn',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() btnType: string = SUBMIT_LABEL;
  @Input() btnClass: string = Button.DarkBlue;
  @Input() disabled: boolean = false;
  @Input() applyTextCenter: boolean = true;

  getButtonClasses() {
    return {
      'text-center': this.applyTextCenter,
      [this.btnClass]: true
    };
  }
}
