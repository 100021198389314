import { Injectable, inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AuthService } from "@core/services/auth.service";
import { EncryptionService } from "@core/services/encryption.service";
import { UserService } from "@core/services/user.service";
import { take } from "rxjs";

@Injectable()
export class UserValidator {
  constructor(
    private user: UserService,
    private auth: AuthService,
    private encryption: EncryptionService
  ) {}

  validateEmailAddress(emailAddress: string, initialValue?: any) {
    return (control: FormGroup) => {
      let value = control.controls[emailAddress].value;
      const form = control.controls[emailAddress];
      if (value != '' && value != initialValue) {
        let encodedEmail: string | null = null;
        if (value && value.includes('+')) {
          encodedEmail = value.replace(/\+/g, '%2B')
        }
        this.user.isDuplicateEmail(encodedEmail ?? value)
          .subscribe((res: boolean) => {
            return res ? form.setErrors({ invalidEmail: true, customError: 'Email already exists.' }) : null
          })
      }
    }
  };

  verifyPassword(password: string) {
    return (control: FormGroup) => {
      let value = control.controls[password].value;
      const form = control.controls[password];

      if (value != '') {
        const req = {
          password: this.encryption.encryptPassword(value)
        }
        this.auth.verifyPassword(req).pipe(take(1)).subscribe((success: boolean) => {
          return !success ? form.setErrors({ invalidPassword: true, customError: 'Password is not valid.' }) : null;
        })
      }
    }
  }
}
