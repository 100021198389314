import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Button } from '@core/enums/button';
import { IConfirmationDialogConfig } from '@core/interfaces/iconfirmation-dialog-config';

@Component({
  selector: 'sjc-admin-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss'
})
export class ConfirmationDialogComponent {
  Button = Button;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: IConfirmationDialogConfig
  ) {
    dialogRef.disableClose = true;
  }

  submit(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
