<div [ngClass]="cardClass" class="flex flex-col gap-y-4">
  <div>
    <img [src]="imageLink"/>
  </div>
  <div class="p-4 px-6 flex flex-col gap-y-4">
    <h1 *ngIf="title" class="font-semibold text-left">{{ title }}</h1>
    <p class="text-[14px] leading-4 text-left">{{ description }}</p>
    <p *ngIf="secondaryDescription" class="text-left">{{ secondaryDescription }}</p>
    <sjc-admin-btn [btnClass]="btnClass">{{ btnLabel }}</sjc-admin-btn>
  </div>
</div>
