import { Component, Input, inject } from '@angular/core';
import { INavbarItems } from '@core/interfaces/inavbar-item';
import { Router } from '@angular/router';
import { Navbar } from '@core/enums/navbar';
import { ISchool } from '@core/interfaces/ischool';

@Component({
  selector: 'sjc-admin-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrl: './side-navbar.component.scss'
})
export class SideNavbarComponent {
  private router = inject(Router);

  @Input() isMobile: boolean = false;
  navbarItems: INavbarItems[] = [];
  Navbar = Navbar;
  currentUrl: string = 'admin';

  ngOnInit(): void {
    this.setMenuItems();
    this.currentUrl = this.router.url;
  }

  setMenuItems(): void {
    this.navbarItems = [
      { label: 'Dashboard', icon: 'uil uil-home', link: '/admin'},
      { label: 'Manage Users', icon: 'uil uil-user', link: '/admin/manage-users'},
      { label: 'Menu Editor', icon: 'uil uil-file', link: '/admin/menu-editor'},
      { label: 'Podcasts', icon: 'uil uil-microphone', link: '/admin/podcasts'},
      { label: 'Quotes', icon: 'uil uil-comment-alt-lines', link: '/admin/quotes'}
    ]
  }

  goHome(): void {
    this.router.navigateByUrl('');
  }
}
