import { ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ButtonComponent } from "./components/button/button.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { InputComponent } from "./components/input/input.component";
import { CardComponent } from "./components/card/card.component";
import { ToastComponent } from "./components/toast/toast.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { MatSidenavModule } from '@angular/material/sidenav';
import { TableComponent } from "./components/table/table.component";
import { FilterComponent } from "./components/filter/filter.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { SideNavbarComponent } from "./components/side-navbar/side-navbar.component";
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { HeroComponent } from "./components/hero/hero.component";
import { UserMenuComponent } from "./components/user-menu/user-menu.component";
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

export const Components = [
  HeaderComponent,
  FooterComponent,
  ButtonComponent,
  NavbarComponent,
  InputComponent,
  CardComponent,
  ToastComponent,
  CarouselComponent,
  TableComponent,
  FilterComponent,
  PaginatorComponent,
  SideNavbarComponent,
  ConfirmationDialogComponent,
  HeroComponent,
  UserMenuComponent
]

export const Modules = [
  ReactiveFormsModule,
  MatSidenavModule,
  MatTableModule,
  MatDialogModule,
  MatSelectModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatMenuModule,
  DragDropModule,
  MatFormFieldModule, 
  MatInputModule, 
  MatDatepickerModule,
  MatNativeDateModule
]
