import { Component } from '@angular/core';

@Component({
  selector: 'sjc-admin-filter',
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {

}
