import { NgModule } from "@angular/core";
import { ToStringPipe } from "./to-string.pipe";

@NgModule({
    imports: [
    ],
    declarations: [
      ToStringPipe,
    ],
    exports: [
      ToStringPipe,
    ]
  })
export class PipesModule {
  static forRoot() {
    return {
        ngModule: PipesModule,
        providers: [],
    };
 }
}
