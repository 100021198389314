import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms'
import { Components, Modules } from './module-helper';
import { UserValidator } from '@core/validators/user.validator';
import { PipesModule } from '@core/pipes/pipes.module';
import { FileDropDirective } from '@core/directives/file-drop.directive';
import { TooltipDirective } from '@babybeet/angular-tooltip';

@NgModule({
  declarations: [
    Components,
    FileDropDirective
  ],
  imports: [
    Modules,
    CommonModule,
    RouterModule,
    FormsModule,
    PipesModule.forRoot(),
    TooltipDirective
  ],
  exports: [
    Components,
    Modules,
    CommonModule,
    RouterModule,
    FormsModule,
    FileDropDirective,
    TooltipDirective
  ],
  providers: [
    UserValidator
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
