import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    private encryptionKey: string = environment.encryptionKey; // Replace with your encryption key

    encryptPassword(password: string): string {
        const key = CryptoJS.enc.Utf8.parse(this.encryptionKey);
        const iv = CryptoJS.lib.WordArray.random(16);

        const encrypted = CryptoJS.AES.encrypt(password, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC // Set the mode explicitly
        });

        const encryptedPassword = iv.concat(encrypted.ciphertext);
        const encryptedPasswordBase64 = CryptoJS.enc.Base64.stringify(encryptedPassword);
        return encryptedPasswordBase64;
    }
}
