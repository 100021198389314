import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, Observable, of, tap } from 'rxjs';
import { ToastService } from './toast.service';
import { SpinnerService } from './spinner.service';
import { IToast } from '@core/interfaces/itoast';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(private http: HttpClient, private toast: ToastService, private spinner: SpinnerService) { }


  get(path: string, message?: IToast): Observable<any> {
    this.spinner.show()
    return this.http.get(path, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    )
  }

  post(path: string, payload: any, message?: IToast): Observable<any> {
    this.spinner.show()
    return this.http.post(path, payload)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    );
  }

  postWithOptions(path: string, payload: any, options: any, message?: IToast): Observable<any> {
    this.spinner.show()
    return this.http.post(path, payload, options)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    );
  }

  delete(path: string, payload: any, message?: IToast): Observable<any> {
    this.spinner.show()

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: payload
    };

    return this.http.delete(path, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    );
  }

  patch(path: string, payload: any, message?: IToast): Observable<any> {
    this.spinner.show()
    return this.http.patch(path, payload)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    );
  }

  put(path: string, payload: unknown, message?: IToast): Observable<any> {
    this.spinner.show()
    return this.http.put(path, payload)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(() => this.spinner.hide())
    );
  }

  private handleSuccess(message?: IToast) {
    if (message) {
      this.toast.success(message)
    }
  }

  private handleError(err: HttpErrorResponse) {
    let message = 'Something went wrong.';
    if (err.error) {
      message = (err.error && typeof err.error === 'string') ? err.error : err.error.ErrorMessage ? err.error.ErrorMessage : err.message;
    }
    this.toast.error({ message: message, header: 'Error' })
    return of(false);
  }
}
