import { Component, Input } from '@angular/core';
import { INavbarItems } from '@core/interfaces/inavbar-item';

@Component({
  selector: 'sjc-admin-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  @Input() isMobile: boolean = false;
  navbarItems: INavbarItems[] = [];

  ngOnInit(): void {
    this.setMenuItems();
  }

  setMenuItems(): void {
    this.navbarItems = [
      { label: 'About Us', link: '/about'},
      { label: 'Resources for family and caregivers', link: '/'},
      { label: 'Contact Us', link: '/contact'},
    ]
  }
}
