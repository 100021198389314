export const environment = {
  production: false,
  apiUrl: 'https://devapi.sjcyouthwellness.com/',
  url: 'siteUrl',
  authToken: 'sjc-admin-token',
  encryptionKey: 'v4jwa72UkwZZ8bcXXyHpiTfsXkciQVvA',
  recaptcha: {
    siteKey: '6Ld_95goAAAAAMCslsUuHZhhB0oFe92t9TTB-NuP',
  },
}
