<div *ngIf="!isMobile" class="py-2">
  <ul class="sm:flex text-base">
    <li *ngFor="let menuItem of navbarItems" class="text-black mr-8">
      <a [href]="menuItem.link" class="hover:text-gray-400">{{ menuItem.label }}</a>
    </li>
  </ul>
</div>

<div *ngIf="isMobile" class="py-4">
  <div class="flex justify-end pr-4">
    <i class="uil uil-multiply font-bold text-[24px] cursor-pointer"></i>
  </div>
  <ul class="flex flex-col text-base p-6">
    <li>
      <sjc-admin-btn>
        <span class="font-medium">
          Suicide Prevention Lifeline 988
        </span>
      </sjc-admin-btn>
    </li>
    <li *ngFor="let menuItem of navbarItems" class="text-black pl-2 mt-2">
      <a [href]="menuItem.link" class="hover:text-gray-400">{{ menuItem.label }}</a>
    </li>
  </ul>
</div>


