import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@core/constants/regex';
import { InputType } from '@core/enums/input-type';

@Component({
  selector: 'sjc-admin-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent {
  @ViewChild('checkbox') checkbox: ElementRef | undefined;

  @Output() search = new EventEmitter();
  @Output() fileChange = new EventEmitter();
  @Output() selectChange: EventEmitter<any> = new EventEmitter();
  @Output() checkboxClick: EventEmitter<any> = new EventEmitter();
  @Input() inputType: string = 'text';
  @Input() control: FormControl | any = new FormControl();
  @Input() label: string = '';
  @Input() ariaLabel: string = '';
  @Input() required: boolean = false;
  @Input() placeHolder: string = '';
  @Input() hasSearch: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() labelColor: string = 'text-black';
  @Input() selectList: any[] = [];
  @Input() selectPlaceholder: string = 'Select';
  @Input() labelId: string = '';
  @Input() checked: boolean = false;
  @Input() checkboxValue!: number;
  @Input() enableEnterKey: boolean = false;
  @Input() customErrorMsg: string = '';
  @Input() maxLength: number = 1000;
  @Input() maxDate?: Date;
  @Input() minDate?: Date;

  @Output() enterPressed = new EventEmitter<void>();
  InputType = InputType;
  EmailRegex = EMAIL_REGEX;
  PasswordRegex = PASSWORD_REGEX;
  selectedFile: string = '';

  ngOnInit(): void {
    if (this.label != '') {
      this.labelId = this.label.replace(/\s/g, "") + new Date().getMilliseconds();
    } else if (this.ariaLabel != '') {
      this.labelId = this.ariaLabel.replace(/\s/g, "") + new Date().getMilliseconds();
    }
  }

  searchInput(value: string): void {
    this.search.emit(value);
  }

  onEnterKey() {
    if (this.enableEnterKey) {
      this.enterPressed.emit();
    }
  }

  file(file: any): void {
    if (file.target) {
      this.fileChange.emit(file.target.files[0]);
      this.selectedFile = file.target.files[0].name;
    }
    else {
      this.fileChange.emit(file);
      this.selectedFile = file.name;
    }
  }

  handleSelectChange(event: any): void {
    this.selectChange.emit(event);
  }

  handleCheckboxClick(event?: number): void {
    event ? this.checkboxClick.emit(event) :
      this.checkboxClick.emit(true);
  }
}
